import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import SeminarForm from "components/seminar/SeminarForm";
import Link from "lib/Link";

const getSeminarDetail = gql`
  query getSeminarDetail($form: SearchForm) {
    result: getSeminarDetail(Input: $form) {
      sn
      dbid
      seminarName
      intro
      startdate
      expdate
      activityTime
      registerSdate
      registerEdate
      location
      speakers
      target
      maxAttendence
      url
      cretime
      updtime
      mailDate
      mailedDate
      applyUrl
      activitySdate
      activityEdate
      isguest
      applyCnt
    }
  }
`;

@withI18next(["common"])
@page
class SeminarDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent: "",
    };
  }

  componentDidMount() {
    //console.log(this.props);
    this.getDetail(this.props);
  }

  @autobind
  getDetail(props) {
    //console.log("props.search.sn="+props.search.sn);
    client.jumperrwdClient
      .query({
        query: getSeminarDetail,
        variables: {
          form: {
            id: props.search.id,
          },
        },
      })
      .then((res) => {
        const { result } = res.data;
        console.log(result);

        //result.cretime = result.cretime.substring(0,4) + "/" + result.cretime.substring(5,6) + "/" + result.cretime.substring(7,8);
        //result.updtime = result.updtime.substring(0,4) + "/" + result.updtime.substring(5,6) + "/" + result.updtime.substring(7,8);

        this.setState({ pageContent: result });
      });
  }

  render() {
    let startdate = this.state.pageContent.registerSdate
      ? this.state.pageContent.registerSdate.substring(0, 4) +
        "-" +
        this.state.pageContent.registerSdate.substring(4, 6) +
        "-" +
        this.state.pageContent.registerSdate.substring(6, 8) +
        " " +
        this.state.pageContent.registerSdate.substring(8, 10) +
        ":" +
        this.state.pageContent.registerSdate.substring(10, 12)
      : "";
    let enddate = this.state.pageContent.registerEdate
      ? this.state.pageContent.registerEdate.substring(0, 4) +
        "-" +
        this.state.pageContent.registerEdate.substring(4, 6) +
        "-" +
        this.state.pageContent.registerEdate.substring(6, 8) +
        " " +
        this.state.pageContent.registerEdate.substring(8, 10) +
        ":" +
        this.state.pageContent.registerEdate.substring(10, 12)
      : "";
    //console.log("startdate="+startdate);
    //console.log("enddate="+enddate);

    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("hyint.common.seminar")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>

              <div className="detail_block">
                <div className="info">
                  <h3>{this.state.pageContent.seminarName}</h3>
                  <ul>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.activity_time")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {this.state.pageContent.activityTime}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.location")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {this.state.pageContent.location}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.speakers")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {this.state.pageContent.speakers}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.target")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {this.state.pageContent.target}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.register_sdate")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {startdate}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.register_edate")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {enddate}
                    </li>
                    {this.state.pageContent.maxAttendence !== 0 && (
                      <li>
                        <span>
                          {this.props.t("hyint.esource.seminar.leave")}
                          {this.props.t("hyint.esource.seminar.colon")}
                        </span>
                        {this.state.pageContent.maxAttendence -
                          this.state.pageContent.applyCnt}
                      </li>
                    )}
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.max_attendence")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      {this.state.pageContent.maxAttendence === 0
                        ? this.props.t("hyint.esource.seminar.Infinite")
                        : this.state.pageContent.maxAttendence}
                    </li>
                    <li>
                      <span>
                        {this.props.t("hyint.esource.seminar.intro")}
                        {this.props.t("hyint.esource.seminar.colon")}
                      </span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.pageContent.intro,
                        }}></p>
                    </li>
                    {this.state.pageContent.applyUrl !== "" && (
                      <li>
                        <span>
                          {this.props.t("hyint.esource.seminar.apply_url")}
                          {this.props.t("hyint.esource.seminar.colon")}
                        </span>
                        <Link
                          href={this.state.pageContent.applyUrl}
                          target="_blank">
                          {this.state.pageContent.applyUrl}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
                {this.state.pageContent.applyUrl === "" && (
                  <div className="apply_form">
                    <h3>
                      {this.props.t("hyint.esource.seminar.tableAreaTip")}
                    </h3>
                    <SeminarForm
                      {...this.props}
                      seminarDetail={this.state.pageContent}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default SeminarDetail;
